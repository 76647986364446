import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import StartMenu from "./components/MenuStart";
import GameMap from "./components/GameMap";
import ScoreBoard from "./components/ScoreBoard";
import UserInputBar from "./components/UserInputBar";
import MenuGameEnd from "./components/MenuGameEnd";
import MenuMultiplayer from "./components/MenuMultiplayer";
import MenuCredit from "./components/MenuCredit";
import Credit from "./components/Credit";
import DialogAreYouSure from "./components/DialogAreYouSure";
import DialogInstructions from "./components/DialogInstructions";
import MultiplayerService from "./middleware/MultiplayerService";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import utils from "./utils";
import BigTitleOverlay from './components/BigTitleOverlay'
import RecentAnswersOverlay from "./components/RecentAnswersOverlay";


const urlParams = new URLSearchParams(window.location.search);

const DEFAULT_VIEWPORT = {
  center: [51.505, -0.09],
  zoom: 1,
};

const DEFAULT_TIMER = 60 * 25;
const DEFAULT_TIMER_CHALLENGE = 20;

const WAIT_NEXT_PLACE_PAN_MS = 5000;
const WAIT_NEXT_PLACE_PAN_MS_AFTER_BOT = 5000;

// @TODO: move game_mode values to globals file instead of using strings
// @TODO: use router instead of 'hide/show' variables in state
// @TODO: seperate logic to seperate files:
//    game mode 'default' & game mode 'challenge'  logic
//    singleplayer logic & multiplayer logic

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameStatus: "pending",
      mapViewport: DEFAULT_VIEWPORT,
      gameSession: {},
      tick: { current: 0 }
    };

    this.multiplayer = new MultiplayerService({
      getSession: () => {
        return this.state.gameSession;
      },
      onPlaceFound: this.onPlaceFound,
      onSessionUpdate: this.onSessionUpdate,
    });
  }

  onSessionUpdate = (updatedSessionReq) => {
    let updatedSession = Object.assign(
      JSON.parse(JSON.stringify(this.state.gameSession)),
      updatedSessionReq
    );
    //console.log("updated session", updatedSession);

    // Skip update if game is over
    if (
      this.state.gameStatus == "gameLost" ||
      this.state.gameStatus == "gameWon"
    ) {
      return;
    }

    // Start game if local session has not started
    if (
      this.state.gameStatus != "playing" &&
      updatedSessionReq.state == "playing"
    ) {
      this.startGame({
        isMultiplayer: true,
        game_mode: updatedSession.game_mode,
      });
    }

    // Add gid param to url if multiplayer game has started
    if (
      this.state.isMultiplayer &&
      !urlParams.get("gid") &&
      updatedSessionReq.gid
    ) {
      window.history.replaceState(
        { gid: updatedSessionReq.gid },
        "InGame",
        "?gid=" + updatedSessionReq.gid
      );
    }

    // Focus map on next place
    if (
      this.state.gamePlaces &&
      this.state.gameSession.game_mode == "challenge"
    ) {
      this.focusOnNextplace(updatedSession);
    }

    // Update state with updated session
    this.setState((state) => {
      if (this.multiplayer.playerUID) {
        state.playerUID = this.multiplayer.playerUID;
      }

      console.log("state.gameSession.currentPlacePos", state.gameSession.currentPlacePos);
      // Start the timer
      if (state.gameSession.currentPlacePos != undefined && state.gameSession.currentPlacePos != updatedSession.currentPlacePos) {
        state.tick = { current: DEFAULT_TIMER_CHALLENGE };
      }

      state.gameSession = updatedSession;

      // Calc players scores
      if (state.gameSession.players) {
        state.gameSession.players.forEach(
          (p) =>
          (p.score = state.gameSession.submissions.filter(
            (s) => s.uid == p.uid
          ).length)
        );
      }
      return state;
    });

    // Update local session in local storage
    window.localStorage.clear();
    if (updatedSession.gid) {
      window.localStorage.setItem(
        updatedSession.gid,
        JSON.stringify(this.state)
      );
    }
  };

  onPlaceInput = (input) => {
    let placeFound = this.state.gamePlaces.find((place) => {
      return place.nicknames.indexOf(utils.indexPlaceName(input)) > -1;
    });
    let isValidAnswer = !(
      placeFound &&
      this.state.gameSession.submissions.find(
        (s) => s.place_code == placeFound.id
      )
    );

    // if challenge mode, check if current place
    if (
      this.state.gameSession.game_mode == "challenge" &&
      placeFound &&
      isValidAnswer &&
      placeFound.id !=
      this.state.gameSession.placesList[
      this.state.gameSession.currentPlacePos
      ]
    ) {
      isValidAnswer = false;
    }

    if (placeFound && isValidAnswer) {
      let submission = { place_code: placeFound.id, uid: this.state.playerUID, submissionTime: (new Date().getTime()) };
      this.setState(
        (state) => {
          state.gameSession.submissions.push(submission);
          state.gameSession.submissions.sort(function (a, b) {
            return a.place_code.localeCompare(b.place_code);
          });
          return state;
        },
        () => {
          this.onPlaceFound(submission);
        }
      );

      if (this.state.isMultiplayer) {
        this.multiplayer.requestPlaceFound(submission);
      }
    }

    return isValidAnswer && placeFound;
  };

  onPlaceFound = (submission) => {
    // @TODO: if challnge, and bot has found, clear the input bar

    let place = this.state.gamePlaces.find(
      (p) => p.id == submission.place_code
    );

    if (
      this.state.gameSession.game_mode != "challenge" &&
      submission.uid == this.state.playerUID
    ) {
      this.focusOnPlace(place);
    }

    this.setState(
      (state) => {
        // calc score for all players
        state.gameSession.players.forEach(
          (p) =>
          (p.score = state.gameSession.submissions.filter(
            (s) => s.uid == p.uid
          ).length)
        );

        // if challenge mode, set current place pos + 1
        if (
          this.state.gameSession.game_mode == "challenge" &&
          !state.isMultiplayer
        ) {
          ++state.gameSession.currentPlacePos;
        }

        return state;
      },
      () => {
        if (
          this.state.gameSession.game_mode == "challenge" &&
          !this.state.isMultiplayer
        ) {
          this.focusOnNextplace(this.state.gameSession);
        }

        this.checkWinCondition();
        this.checkLoseCondition();
      }
    );
  };

  focusOnNextplace = (updatedSession) => {
    let currPlace = this.state.gamePlaces.find((p) => p.id == updatedSession.placesList[updatedSession.currentPlacePos]);
    let lastSubmission = updatedSession.submissions.find((s) => s.place_code == updatedSession.placesList[updatedSession.currentPlacePos - 1]);

    if (!currPlace) return;

    // show big title with country found
    if (lastSubmission) {
      let lastSubmissionPlace = this.state.gamePlaces.find((p) => p.id == lastSubmission.place_code);
      this.setState({
        hideCurrentlyFocused: true,
        bigTitleOverlay: { title: lastSubmissionPlace.eng, color: (lastSubmission.uid == "bot") ? 'red' : 'white' }

      });
    }

    // using this helper variable to verify only the last time this function is called should run.
    let _localHelperRunCount = this._helperRunCount;

    setTimeout(
      () => {
        // if a newer place has been found, we skip focusing on this place found
        if (_localHelperRunCount != this._helperRunCount) return;
        if (this.state.gameStatus != 'playing') return;

        // reset the big title shown, and show the newly focused location
        this.setState({ bigTitleOverlay: null, hideCurrentlyFocused: false });
        this.focusOnPlace(currPlace);
        this._helperRunCount = (_localHelperRunCount || 0) + 1;
      },
      lastSubmission && lastSubmission.uid == "bot"
        ? WAIT_NEXT_PLACE_PAN_MS_AFTER_BOT
        : WAIT_NEXT_PLACE_PAN_MS
    );
  };

  focusOnPlace(place, zoom) {
    let zoomByArea = 4;
    if (place.area > 5000000) { zoomByArea = 3; }
    else if (place.area > 1000000) { zoomByArea = 4; }
    else if (place.area > 500000) { zoomByArea = 4; }

    this.setState({
      mapViewport: { zoom: zoom || zoomByArea, center: [place.lat, place.lon] },
    });
  }

  checkWinCondition = () => {
    let foundAllPlaces = this.state.gameSession.submissions.length == this.state.gamePlaces.length;

    ///if (this.state.gameSession.game_mode != "challenge") {
    ///  foundAllPlaces =
    ///    this.state.gameSession.submissions.filter((s) => s.uid != "bot")
    ///      .length == this.state.gamePlaces.length;
    ///}

    if (this.state.gameStatus == "playing" && foundAllPlaces) {
      this.setState({ gameStatus: "gameWon" });
      clearInterval(this.timer);
    }
  };

  checkLoseCondition = () => {
    if (
      this.state.gameStatus == "playing" &&
      this.state.gameSession.game_mode != "challenge" &&
      this.state.tick.current > 0
    )
      return;

    //@TODO: what is the lose condition for game mode challenge
    if (
      this.state.gameStatus == "playing" &&
      this.state.gameSession.game_mode == "challenge"
    )
      return;


    this.setState({ gameStatus: "gameLost" });
    clearInterval(this.timer);
  };

  onEndGame = () => {
    // reset all places to not found
    console.log("end game called");
    clearInterval(this.timer);
    this.setState({
      showQuitDialog: false,
      gameStatus: "gameLost",
      tick: { current: DEFAULT_TIMER },
      bigTitleOverlay: null,
      hideCurrentlyFocused: true
    });

    if (this.state.isMultiplayer) {
      this.multiplayer.disconnect();
    }

    window.history.replaceState({}, "The Geo Quiz", "?start");
  };

  startGame = (options) => {
    console.log("Start game was called", options, this.state);
    this.setState({
      isMultiplayer: options.isMultiplayer || false,
      gameStatus: "playing",
      game_mode: options.game_mode,
      tick: options.game_mode == "challenge" ? { current: 0 } : { current: DEFAULT_TIMER },
    });

    if (!this.timer) {
      this.timer = setInterval(() => {
        this.setState({ tick: { current: --this.state.tick.current } });
      }, 1000);
    }

    if (options.isMultiplayer && options.requestGameStart === true) {
      this.multiplayer.requestStartGame(options);
    }

    if (!options.isMultiplayer) {
      let gameSessionSinglePlayer = {
        gid: "singleplayer",
        players: [{ uid: "singleplayer" }],
        submissions: [],
        game_mode: options.game_mode,
      };

      if (gameSessionSinglePlayer.game_mode == "challenge") {
        gameSessionSinglePlayer.placesList = utils.getShuffledPlaces(
          this.state.gamePlaces.map((p) => _.omit(p, "features"))
        );
        gameSessionSinglePlayer.currentPlacePos = 0;
      }

      this.setState(
        {
          playerUID: "singleplayer",
          gameSession: gameSessionSinglePlayer,
        },
        () => {
          if (gameSessionSinglePlayer.game_mode == "challenge") {
            let currPlace = this.state.gamePlaces.find(
              (p) =>
                p.id ==
                this.state.gameSession.placesList[
                this.state.gameSession.currentPlacePos
                ]
            );
            this.focusOnPlace(currPlace);
          }
        }
      );
    }

    if (options.userInitiated) {
      utils.openFullscreen();
    }
  };

  restartGame = () => {
    this.setState({
      gameStatus: "pending",
      showQuitDialog: false,
      showCreditMenu: false,
      gameSession: {},
      mapViewport: DEFAULT_VIEWPORT,
      hideInstructions: false,
    });

    window.history.replaceState({}, "The Geo Quiz", "?start");
  };

  joinMultiplayerSession = (options) => {
    this.setState({
      gameStatus:
        options && (options.createGame || options.isHost)
          ? "pendingMultiplayerHost"
          : "pendingMultiplayer",
      isMultiplayer: true,
      showCreditMenu: false
    });
    this.multiplayer.connect(options || {});
  };

  joinSingleplayer = () => {
    this.setState({
      isMultiplayer: false,
      gameStatus: "playing",
      gameSession: {
        gid: "singleplayer",
        players: [{ uid: "singleplayer" }],
        submissions: [],
        game_mode: "challenge",
      },
    });
  };

  onInstructionsDismissed = () => {
    console.log("On instructions dismissed", this.state);
    if (
      this.state.isMultiplayer &&
      this.state.gamePlaces &&
      this.state.gameSession.game_mode == "challenge"
    ) {
      let currPlace = this.state.gamePlaces.find(
        (p) =>
          p.id ==
          this.state.gameSession.placesList[
          this.state.gameSession.currentPlacePos
          ]
      );
      this.focusOnPlace(currPlace);
    }
    utils.openFullscreen();
    this.setState({ hideInstructions: true });
  };

  componentDidMount() {
    fetch("data/world/countries.geo.json")
      .then((response) => response.json())
      .then((data) => {
        let places = Object.keys(data.names).map((place) => {
          let nicknames = [
            data.names[place].heb,
            data.names[place].eng,
            ...data.names[place].syn.split(",").filter((n) => n != ""),
          ].map(utils.indexPlaceName);

          return {
            id: place,
            ...data.names[place],
            status: "notfound",
            nicknames: nicknames,
            features: data.geoJSON.features.find((f) => f.id == place),
          };
        });

        this.setState({
          gamePlaces: places,
          //gamePlaces: places.filter((v, i) => {
          //  return v.id == "CAN" || v.id == "USA" || v.id == "MEX";
          //}),
        });
      });

    let savedSession = JSON.parse(
      window.localStorage.getItem(urlParams.get("gid"))
    );
    if (savedSession) {
      console.log("loading session", savedSession);
      this.setState(savedSession);
      this.onSessionUpdate(savedSession.gameSession);
    }

    // Join game if gid is found
    console.log("the saved session:", savedSession);
    if (urlParams.get("gid")) {
      this.joinMultiplayerSession({
        joinGame: true,
        isHost: savedSession && savedSession.playerUID == savedSession.gameSession.host,
        gid: urlParams.get("gid"),
        uid: savedSession?.playerUID,
        avatar: savedSession?.gameSession?.players?.find((p) => p.uid == savedSession.playerUID)?.avatar
      });
    }

    let setDocHeight = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${0.8 * window.innerHeight}px`
      );

      if (
        this.state.gamePlaces &&
        this.state.gameSession.game_mode == "challenge" &&
        this.state.gameSession.placesList
      ) {
        this.focusOnNextplace(this.state.gameSession);
      }
    };
    setDocHeight();

    window.addEventListener("resize", setDocHeight);
    window.addEventListener("orientationchange", setDocHeight);

    //Cheat 
    if (urlParams.get("cheat") == '1') {
      window.addEventListener("keypress", (e) => {
        if (e.key == "c") {
          const randPlaceIndex = _.random(0, this.state.gamePlaces.length - 1);
          console.log("random place", this.state.gamePlaces[randPlaceIndex].eng);
          this.onPlaceInput(this.state.gamePlaces[randPlaceIndex].eng);
        }

      })
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div className={"App stage-" + this.state.gameStatus}>
        {!this.state.showCreditMenu && (
          <div className="screen">
            {this.state.gameStatus == "pending" && (
              <StartMenu
                onStartSinglePlayer={() => {
                  this.joinSingleplayer();
                }}
                onStartMultiPlayer={() => {
                  this.joinMultiplayerSession({ createGame: true });
                }}
              />
            )}

            {(this.state.gameStatus == "playing" ||
              this.state.gameStatus == "gameLost" ||
              this.state.gameStatus == "gameWon") && (
                <>
                  <ScoreBoard {...this.state} />
                  <GameMap {...this.state} />
                  <UserInputBar
                    {...this.state}
                    onPlaceInput={this.onPlaceInput}
                    onEndGame={() => {
                      this.setState({ showQuitDialog: true });
                    }}
                  />

                  {this.state.bigTitleOverlay && <BigTitleOverlay allowSkip={!this.state.isMultiplayer} {...this.state.bigTitleOverlay} />}
                  {!this.state.bigTitleOverlay && this.state.gameSession.submissions && <RecentAnswersOverlay
                    players={this.state.gameSession.players}
                    submissions={this.state.gameSession.submissions} gamePlaces={this.state.gamePlaces} />}

                  {!this.state.hideInstructions && (
                    <DialogInstructions
                      {...this.state}
                      onDismiss={this.onInstructionsDismissed}
                      startGame={this.startGame}
                    />
                  )}
                </>
              )}

            {(this.state.gameStatus == "pendingMultiplayer" ||
              this.state.gameStatus == "pendingMultiplayerHost") && (
                <MenuMultiplayer
                  {...this.state}
                  onAvatarSelected={(avatarId) => {
                    this.multiplayer.requestUpdatePlayer({ avatar: avatarId });
                  }}
                  startGame={this.startGame}
                />
              )}
          </div>
        )}

        {(this.state.gameStatus == "gameWon" ||
          this.state.gameStatus == "gameLost") && (
            <MenuGameEnd {...this.state} restartGame={this.restartGame} />
          )}

        {this.state.showQuitDialog && (
          <DialogAreYouSure
            onYes={this.onEndGame}
            onNo={() => {
              this.setState({ showQuitDialog: false });
            }}
          />
        )}
        {!this.state.showCreditMenu && this.state.gameStatus != "playing" && (
          <Credit
            onCreditClick={() => {
              this.setState({ showCreditMenu: true });
            }}
          />
        )}
        {this.state.showCreditMenu && (
          <MenuCredit
            onCreditClose={() => {
              this.setState({ showCreditMenu: false });
            }}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(App);
