class VoiceService {
  isRecording;

  constructor(props) {
    this.props = props;

    if (
      typeof window.SpeechRecognition == "undefined" &&
      typeof window.webkitSpeechRecognition == "undefined"
    ) {
      return;
    }

    let placesList = this.props.gamePlaces.map((place) => place.eng);

    var SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    var SpeechGrammarList =
      window.SpeechGrammarList || window.webkitSpeechGrammarList;
    var SpeechRecognitionEvent =
      window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;
    var grammar =
      "#JSGF V1.0; grammar countries; public <country> = " +
      placesList.join(" | ") +
      " ;";

    this.recognition = new SpeechRecognition();
    var speechRecognitionList = new SpeechGrammarList();

    speechRecognitionList.addFromString(grammar, 1);
    this.recognition.grammars = speechRecognitionList;

    this.recognition.lang = "en-US";
    //this.recognition.lang = 'he-IL';
    SpeechRecognition.continuous = true;
    this.recognition.interimResults = true;
    this.recognition.maxAlternatives = 1;

    this.recognition.onresult = this.onResult;
    this.recognition.onerror = this.onError;
    this.recognition.onend = this.onEnd;
  }

  onMicClick = () => {
    if (this.isRecording) {
      this.recognition.stop();
      this.isRecording = false;
      console.log("stopped");


    } else {
      this.recognition.start();
      this.isRecording = true;

      console.log("started");
    }

    return this.isRecording;
  };

  onResult = (event) => {
    var last = event.results.length - 1;
    var transcript = event.results[last][0].transcript
      .replace("st.", "saint")
      .replace("Columbia", "Colombia");
    console.log("on result:", transcript);

    if (event.results[0][0].confidence > 0.6) {
      this.props.onTranscript(transcript);
    }

    this.props.onPlaceInput(transcript);

    let variations = transcript
      .split(" ")
      .concat(transcript.match(/[^\s]+\s[^\s]+/g))
      .concat(
        transcript
          .split(" ")
          .filter((v, i) => {
            return i != 0;
          })
          .join(" ")
          .match(/[^\s]+\s[^\s]+/g)
      );

    console.log("varitations:", variations);
    variations.forEach((place_name) => {
      if (!place_name) return;
      this.props.onPlaceInput(place_name);
    });
  };
  onEnd = () => {
    console.log("ending");

    if (this.isRecording) {
      this.recognition.start();
      console.log("starting again");
    }

    this.props.onPlaceInput("");
  };
  onError = (event) => {
    this.isRecording = true;
    console.log("Error occurred in recognition: " + event.error);
    this.props.onPlaceInput("");
  };
}

export default VoiceService;
